




import { Component, Vue } from 'vue-property-decorator';
import Overview from '@/components/Overview.vue';

@Component({
  components: {
    Overview,
  },
})
export default class TrueFalseOverview extends Vue {}
